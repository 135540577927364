@import './variables';

$active-background: darken($btn-bg, 10%);
$active-border: darken($btn-border, 12%);

.rbc-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
  position: relative;
  flex-direction: row-reverse;

  > span:last-child {
    display: block;
  }
  .rbc-toolbar-label {
    flex-grow: 1;
    padding: 0 10px;
    text-align: center;
    color: #dd0089;
  }

  & button {
    color: #ffffff;
    background-color: #dd0089 !important;
    display: inline-block;
    margin: 0;
    text-align: center;
    vertical-align: middle;
    background: none;
    background-image: none;
    border: 0;
    padding: 0.375rem 1rem;
    border-radius: 4px;
    line-height: normal;
    white-space: nowrap;

    &:active,
    &.rbc-active {
      background-image: none;
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
      background-color: $active-background;
      border-color: $active-border;

      &:hover,
      &:focus {
        color: #ffffff;
        background-color: darken($btn-bg, 17%);
        border-color: darken($btn-border, 25%);
        cursor: pointer;
      }
    }

    &:focus {
      color: #ffffff;
      background-color: $active-background;
      border-color: $active-border;
      cursor: pointer;
    }

    &:hover {
      color: #ffffff;
      background-color: $active-background;
      border-color: $active-border;
      cursor: pointer;
    }
  }
}

.rbc-btn-group {
  display: inline-block;
  white-space: nowrap;

  > button:first-child:not(:last-child) {
    margin: 10px 0 20px;
  }

  > button:last-child:not(:first-child) {
    width: 45px;
    height: 45px;
    background: white !important;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
      0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    padding: 0;
    border-radius: 50%;
    color: #dd0089;
    position: absolute;
    right: 200px;
    opacity: 0;
  }

  .rbc-rtl & > button:first-child:not(:last-child) {
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .rbc-rtl & > button:last-child:not(:first-child) {
    border-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  > button:not(:first-child):not(:last-child) {
    width: 45px;
    height: 45px;
    background: white !important;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
      0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    padding: 0;
    border-radius: 50%;
    color: #dd0089;
    position: absolute;
    left: 140px;
    opacity: 0;
  }

  .nav-buttons {
    width: 45px;
    height: 45px;
    background: white !important;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
      0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    padding: 0;
    border-radius: 50%;
  }

  button + button {
    margin-left: -1px;
  }

  .rbc-rtl & button + button {
    margin-left: 0;
    margin-right: -1px;
  }

  & + &,
  & + button {
    margin-left: 10px;
  }
}
