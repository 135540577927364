body {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafcff;
  height: 100vh;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .MuiCircularProgress-colorPrimary {
  color: '##dd0089' !important;
} */

.makeStyles-toolbar-9 {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  min-height: 64px !important;
}

.MuiDrawer-paperAnchorDockedLeft {
  box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.15);
}

.MuiListItem-gutters {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.MuiListItemIcon-root {
  color: rgba(0, 0, 0, 0.54);
  display: inline-flex;
  min-width: 40px !important;
  flex-shrink: 0;
}

.MuiListItem-button {
  height: 45px;
}

.MuiListItem-root.Mui-selected {
  border-left: 3px solid #dd0089;
  background: rgba(246, 154, 211, 0.3) !important;
}

.MuiGrid-spacing-xs-3 > .MuiGrid-item {
  padding: 0 8px !important;
}
.MuiTableCell-stickyHeader {
  top: 0;
  left: 0;
  z-index: 2;
  position: sticky;
  background-color: #fff !important;
}

.MuiTableCell-root {
  display: table-cell;
  padding: 10px 16px !important;
  font-size: 0.875rem;
  text-align: left;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.43;
  border-bottom: none !important;
  letter-spacing: 0.01071em;
  vertical-align: inherit;
}

.MuiTableCell-head {
  font-size: 14px !important;
  color: #c0c0c0 !important;
  font-weight: 700 !important;
  line-height: 36px !important;
}

.MuiTableCell-body {
  color: #373737 !important;
}

.MuiTablePagination-toolbar {
  margin-top: 20px;
  min-height: 52px;
}

.MuiOutlinedInput-root {
  position: relative;
  border-radius: 30px !important;
}

.MuiOutlinedInput-input {
  padding: 14px !important;
}

.MuiButton-textPrimary {
  color: #dd0089 !important;
}

.MuiTypography-h6 {
  font-size: 16px !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 600 !important;
  line-height: 1.6;
  letter-spacing: 0.0075em;
}

.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
  padding: 4px 8px !important;
}
